<template>
    <div class="container-fluid h-50 my-2">
        <div class="row justify-content-center align-items-center h-50">
            <div class="col-auto">
                <router-link v-if="!isRenderedFromLogin" :to="{ name: 'Home' }" class="d-block mb-3"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
                <div class="box">
                    <h3>{{ $t('compte.validation_email') }}</h3>
                    
                    <!-- Si on est ici pour envoyer la demande de validarion du mail -->
                    <div v-if="!isFromValidationUrl && !success">
                        <p v-if="!isRenderedFromLogin">{{ $t('compte.email_non_confirme_avertissement') }}.</p>
                        <p v-else>{{ $t('compte.changement_licence_requiert_validation_mail') }}.</p>
                        <b-button @click="ask_verif" variant="primary">{{ $t('compte.button_email_verif') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']"/></b-button>
                    </div>
                    
                    <div v-if="success" class="alert alert-success">
                        <font-awesome-icon
                            class="mr-1" 
                            :icon="['far', 'check-circle']"
                        />

                        <span v-if="isFromValidationUrl">
                            {{ $t('compte.validation_enregistree') }}.
                        </span>
                        <span v-else>
                            {{ $t('compte.email_validation_envoye') }}.
                        </span>
                    </div>

                    <font-awesome-icon 
                        v-if="working" 
                        class=""
                        :icon="['fas', 'spinner']"
                        spin
                    />

                    <a href="#" v-if="!isRenderedFromLogin" @click.prevent="goNext" class="d-block mb-3">{{ $t('global.poursuivre') }} <font-awesome-icon class="mr-1" :icon="['fal', 'long-arrow-right']"/></a>


                    <ErrorAlert v-if="error" :messageI18n="error" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import Navigation from '@/mixins/Navigation'

export default {
    name: 'EmailValidation',
    mixins: [User, Navigation],
    data: () => ({
        working: false,
        error: '',
        success: false,

        email: '',
        token: '',
        sign: '',
        user_id: '',
    }),
    created() {
        this.preinit_component()
    },
    mounted() {
        this.init_component()
    },
    methods: {
        preinit_component() {
            this.email = this.$route.params.email 
            this.token = this.$route.params.token
            this.sign  = this.$route.params.signature
			this.user_id = this.$route.params.userid
            this.temp_licence = this.$route.params.temp_licence
        },
        init_component() {
            // Si le composant dispose de ces paramètres, c'est que l'on vient d'un lien de validation de l'email
            if(this.isFromValidationUrl) {
                return this.send_verif()
            }

            if(!this.isFromValidationUrl && !this.success) {
                this.ask_verif()
            }
        },
        async ask_verif() {
            this.working = true
            const result = await this.askValidationEmail(this.temp_licence)

            if(result) {
                this.success = true
            }
            this.working = false
        },     
        async send_verif() {
            this.working = true
            const result = await this.validateEmail(this.user_id, this.token, this.sign)
            this.working = false

            if(result.type == 'failure') {
                // Mauvaise signature d'url
                if(result.status == 403) {
                    this.error = 'global.mauvaise_signature'
                }
                // Trop de tentatives
                else if(result.status == 429) {
                    this.error = 'global.trop_de_tentatives'
                }

                return
            }
            else if(result.type == 'success') {
                this.success = true
				this.hasVerifiedEmail = true
				this.persistUserMailValid(true)
            }
        },
        async goNext() {
            const res = await this.getRedirectUrl()
            if(res.retour.redirect) {
                this.$router.push({
                    name: 'Loading',
                    query: {
                        redirect: res.retour.redirect
                    }
                })
            }
            else {
                this.$router.push({path: 'home'})
            }
        }        
    },
    computed: {
        // Si le user a cliqué sur un lien de validation de son mail
        isFromValidationUrl() {
            return !!this.email && !!this.token && !!this.sign
        },
		isRenderedFromLogin() {
			return !this.isFromValidationUrl
		},
        hasVerifiedEmail: {
            get() {
                return this.$store.state.userAccess.hasEmailVerified
            },
            set(val) {
                this.$store.commit({ type: 'updateUserAccess', accessType: 'hasEmailVerified', val: val })
            }
        },
    },
    components: {
        ErrorAlert: () => import('@/components/Alert/ErrorAlert')
    }
}
</script>